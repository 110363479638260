import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

//const appUrl="http://localhost:8086/dhanvantari_admin/";
//const appUrtPath="http://localhost:8086/dhanvantari_admin/upd/";
const appUrl="https://cms.mbdhwellness.com/controller/pgControl.php?pm=";
const appUrtPath="https://cms.mbdhwellness.com/mbdh/";
const appLoanUrtPath="https://cms.mbdhwellness.com/mbdh/";
export const appGetUrlPath=()=>{
  return appUrtPath;
}
export const appGetMainUrl=()=>{
  return appUrl;
}
export const appGetLoanUrlPath=()=>{
  return appLoanUrtPath;
}

export const displaySteps=(obj,index,divClsName)=>{
  alert($(obj).attr("type"));
 let current_fs = $(obj).parent();
 let next_fs = $(obj).parent().next();
 next_fs.show();
 current_fs.animate({opacity: 0}, {
  step: function(now) {
  // for making fielset appear animation
  let opacity = 1 - now;

  current_fs.css({
  'display': 'none',
  'position': 'relative'
  });
  next_fs.css({'opacity': opacity});
  },
  duration: 500
  });

  alert($(divClsName).length);
  // var tabcontent = document.getElementsByClassName(divClsName);
  // for (var i = 0; i < tabcontent.length; i++) {
  //   tabcontent[i].style.display = "none";
  // }
  // if(index!=-1){
  //   tabcontent[index].style.display="block";
  // }
  
}
export const GetImgCertUserCMS=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : appUrl+curl,
      success : function(data)
      {
         alert(data);
        //var resData=data.documentElement.childNodes[0].nodeValue;
       var resData=data;
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
       //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
       var userMsg="";  
       return obCls[resfun](checkData,checkMsg,userMsg);
          
   },
      error: function(data) {
      //alert(JSON.stringify(data));
         return obCls[resfun](0,data);
       }
     };
   /* for validation control */
   var strFrmNm=frmNm.substr(1);
   var isValidated=true;
   //alert(strFrmNm);
   var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
   for(var r=0;r<objElems.length;r++){
     
     if(objElems[r].value==="" || objElems[r].value==="0"){
       //alert(objElems[r].type);
       objElems[r].style.border="1px solid #e87d1e";
       isValidated=false;
     }
   }
   if(!isValidated){
     return obCls[resfun]("not_validated","","");
   }else{
    //  alert(frmNm);
    $(frmNm).ajaxSubmit( options );
   }
}


export const AjaxCallMethodCMS = (obCls,cBack)=>{

  $("._previousField").click(function(){
   let current_fs = $(this).parent();
   let previous_fs = $(this).parent().prev();
    previous_fs.show();
    current_fs.animate({opacity: 0}, {
      step: function(now) {
      // for making fielset appear animation
      let opacity = 1 - now;

      current_fs.css({
      'display': 'none',
      'position': 'relative'
      });
      previous_fs.css({'opacity': opacity});
      },
      duration: 500
      });

  });

  $("._nextField").click(function(){
    let current_fs = $(this).parent();
 let next_fs = $(this).parent().next();
 next_fs.show();
 current_fs.animate({opacity: 0}, {
  step: function(now) {
  // for making fielset appear animation
  let opacity = 1 - now;

  current_fs.css({
  'display': 'none',
  'position': 'relative'
  });
  next_fs.css({'opacity': opacity});
  },
  duration: 500
  });
  });
  

  $('._brFile').click(function(){
     var getNm=$(this).attr("setTo");
     
     document.getElementById(getNm).click();
     });
  $(".updImg").change(function(){
     //readURL(this);
     //alert("A file has been selected.");
 });
 $(".select_target").change(function () {
  var getCallFunc= $(this).attr("resp_dest");
  var refId=$(this).val();
  return obCls[getCallFunc](refId);
 });
 

   $('._btnSetColor').click(function () {
     //var m_type=$("#m_type").val();
   //var curl_type=$("#curl_type").val();
 //$("#modal_login").serialize();
  var ssFrm=$(this).closest('form');
  var m_type= $(ssFrm).find('input[name="m_type"]').val();
  var curl_type=$(ssFrm).find('input[name="m_type"]').val();
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = $(this).closest('form').serializeArray();
   
   //var xs = "{objs:" + JSON.stringify(ss) + "}";
   var xs=ss;
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
    /* Validation */
    let frmName=ssFrm.attr("id");
    // alert(typeof ssFrm);
    let objElems=document.getElementById(frmName).getElementsByClassName("required");
    let isValidated=true;
    // alert(objElems.length);
    for(var r=0;r<objElems.length;r++){
      if(objElems[r].value==="" || objElems[r].value==="0"){
        objElems[r].style.border="1px solid #e87d1e";
        isValidated=false;
      }
    }
   if(!isValidated){
     return obCls[getCallFunc]("not_validated","Fill blank records!"); 
   }
  // alert(isValidated); 
  // alert(xs);
  
   //ajaxindicatorstart('loading data.. please wait..');
//alert(getCallFunc);
  //var ss=$("#"+fName).serialize();
        $.ajax({
          crossDomain: true,
               type: "POST",
            url: appUrl + curl_type,
            data: xs,
             success: function (data) {
                // alert(data);
                //ajaxindicatorstop();
                   //alert("Registration Completed Successfully ");
                   // document.getElementById("frmRegister").reset();
                   //location.reload();
                    if(getCallFunc!="")
                    {
                       var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                       var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                       return obCls[getCallFunc](checkData,checkMsg);
                    }else
                    {
                      //parent.$.colorbox.close();
                   }
                   
                
               }
           });

});
}

export const CallCMSMapMethod=(obCls,frmValues)=>{
   
    var curl_type=frmValues['curl_type'];
 
     //alert("check url == "+curl_type);
     var divRes="";
     var getCallFunc=""
     if(frmValues['res_name'])
     {
       divRes=frmValues['res_name'];
     }
     if(frmValues['res_func'])
     {
       getCallFunc=frmValues['res_func'];
     }
         var ss=frmValues;
         //var xs = "{objs:" + JSON.stringify(ss) + "}";
        //alert(xs);
          $.ajax({
             crossDomain:true,
                 type: "POST",
                 url: appUrl+curl_type,
                 data:ss,
              success: function (data) {
                  //alert(data);   
               //ajaxindicatorstop();
                  // return data;
                  if(divRes!="")
                  {
                      if ($("#" + divRes).length!=0)
                   $("#"+divRes).html(data);
                  }
                  if(getCallFunc!="")
                  {
                   var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                   var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                   //  eval(getCallFunc);
                  // alert(checkMsg);
                  return obCls[getCallFunc](checkData,checkMsg);
                     }
                    
                 }
             });
 }
export const getRoleDetails=(roles,uriPath)=>{
  const listFltr = d => d.col2 === uriPath && d.status==='side';
  var topMenusList = roles.filter(listFltr);
  var listFilter;
  for(var i=0;i<topMenusList.length;i++){
    const childFltr = d => d.parent_id === topMenusList[i]["parent_id"] && d.status === 'side';
    listFilter = roles.filter(childFltr);
  }
  return listFilter;
}
export const getAccessRolesNew=(roles)=>{

    var getMenu="";
    const listFltr = d => (d.col6 === 'menu' || d.col6 === 'top_menu') && d.parent_id == 0;
    var topMenusList = roles.filter(listFltr);
    //alert(topMenusList.length);
    for (var i = 0; i < topMenusList.length; i++) {
        const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.col6 !== 'desk'
        var childMenuList = roles.filter(childFltr);
        var getChMenu = "";
        //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
        if (childMenuList.length === 0) {
            getMenu += " <li>" +
                "<a href = \"/" + topMenusList[i]["col2"] + "\">" + topMenusList[i]["col1"] + "</a>" +
                "</li>";
        } else {
            getMenu += "<li class=\"dropdown\">" +
            "<a href=\"#\">"+topMenusList[i]["col1"]+" <i class=\"ion-ios-arrow-down\"></i></a>";
               
            getChMenu = "<ul class=\"sub-menu\">";
            for (var ch = 0; ch < childMenuList.length; ch++) {
                const child2Fltr=d => d.parent_id === childMenuList[ch]["id"] && d.col6 === 'menu';
                var child2MenuList=roles.filter(child2Fltr);
                
                if(child2MenuList.length!=0){
                  getChMenu += "<li class=\"dropdown position-static\"><a href=\"" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "<i class=\"ion-ios-arrow-right\"></i></a>";
                  getChMenu+="<ul class=\"sub-menu sub-menu-2\">";
                }else{
                  getChMenu += "<li><a href=\"/" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "</a></li>";
                }
                for(var k=0;k<child2MenuList.length;k++){
                  getChMenu += "<li><a href=\"/" + child2MenuList[k]["col2"] + "\">" + child2MenuList[k]["col1"] + "</a></li>";
                }
                if(child2MenuList.length!=0){
                  getChMenu+="</ul></li>";
                }
            }
            getChMenu += "</ul>";
            getMenu += getChMenu + "</li>";
        }
  
        // getMenu += topMenusList[i]["col1"];
    }
    return getMenu;
   
  }
  export default CallCMSMapMethod